import React from "react";
import { Helmet } from "react-helmet-async";
import { images } from "../constants";

const About = () => {
  return (
    <div className="flex flex-col-reverse md:flex-row items-center justify-center w-full mx-auto">
      <Helmet>
        <title>Defining the future</title>
        <meta
          name="description"
          content="Defining the future. Ownership of one of our NFTs guarantees your ability to vote, gives you VIP access to private, membership-only finance convention in Las Vegas and generated recurring revenue from ongoing royalties"
        />
        <meta
          name="keywords"
          content="nft, dao, investmentjoy, fractional nft, youtube nft, youtuber nft, popular nft, buy nft, real estate nft, business nft"
        />
      </Helmet>
      <div className="flex flex-col items-start mr-5 lg:mr-20 max-w-lg ml-10 mt-20 mb-20">
        <p className="mb-6 font-semibold text-[14px] md:text-[15px] text-[#232323] uppercase">
          This project is a breathtaking piece of artwork represented by 1,728
          tokens. Each NFT is being sold to grant unique and exciting benefits
          such as digital ownership of a portion of real estate.
        </p>
        <p className="mb-6 font-semibold text-[14px] md:text-[15px] text-[#232323] uppercase">
          our project lives at the intersection of art, business, and finance.
          the future of real estate is digital, meaning you can partake in the
          distributed management of the token by voting on how it should be
          governed.
        </p>
        <p className="mb-6 font-semibold text-[14px] md:text-[15px] text-[#232323] uppercase">
          YOUR OWNERSHIP OF EVEN ONE OF THESE NFTS GUARANTEES YOUR ABILITY TO
          VOTE, GIVES YOU VIP ACCESS TO A PRIVATE, MEMBERS-ONLY FINANCE
          CONVENTION IN LAS VEGAS AND GENERATES RECURRING REVENUE FROM ONGOING
          ROYALTIES.
        </p>
        <p className="mb-6 font-semibold text-[14px] md:text-[15px] text-[#232323] uppercase">
          THIS NFT EARNS YOU MONEY WHILE YOU SLEEP - AND GIVES YOU THE
          OPPORTUNITY TO NETWORK WITH BUSINESS, REAL ESTATE AND CRYPTO
          MASTERMINDS.
        </p>
        <p className="mb-6 font-semibold text-[14px] md:text-[15px] text-[#232323] uppercase">
          yOU CAN BUY THE NFT NOW BY CLICKING THE BUTTON BELOW!
        </p>
        <div className="flex flex-row">
          <a
            href="https://opensea.io/collection/investment-joys-ccl-finance-token"
            target="_blank"
            rel="noreferrer"
            className="btn uppercase text-sm"
          >
            Buy now
          </a>
        </div>
      </div>
      <div>
        <img src={images.card} alt="card" className="max-w-sm md:max-w-lg" />
      </div>
    </div>
  );
};

export default About;

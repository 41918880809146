import React from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { images } from "../constants";

const Home = () => {
	return (
		<div className='flex flex-col-reverse mt-10 sm:flex-row items-center justify-center w-full mx-auto'>
			<Helmet>
				<title>Bridge the Digital Physical Divide</title>
				<meta
					name='description'
					content='Bridge the Digital Physical Divide. 1 of 1,728. A chance to own a piece of the future in business, real estate and art.'
				/>
				<meta
					name='keywords'
					content='nft, dao, investmentjoy, fractional nft, youtube nft, youtuber nft, popular nft, buy nft, real estate nft, business nft'
				/>
			</Helmet>
			<div className='flex flex-col items-start mr-5 lg:mr-20 max-w-sm ml-10 mb-10'>
				<h1 className='uppercase font-bold text-[#232323] text-4xl md:text-5xl mb-6 text-center sm:text-left'>
					Exclusive Investment Joy NFT
				</h1>
				<p className='mb-6 font-semibold text-[14px] md:text-[15px] text-[#232323] uppercase max-w-sm'>
					1 of 1,728.
					<br /> A CHANCE TO OWN A PIECE OF THE FUTURE IN BUSINESS, REAL ESTATE
					AND ART.
				</p>
				<div className='flex flex-row'>
					<Link className='btn uppercase text-center ' to='/about'>
						Learn More
					</Link>
					<a
						href='https://opensea.io/collection/investment-joys-ccl-finance-token'
						target='_blank'
						rel='noreferrer'
						className='btn uppercase text-center'>
						Buy now
					</a>
				</div>
			</div>
			<div>
				<img
					src={images.card}
					alt='card'
					className='max-w-[250px] sm:max-w-[350px] md:max-w-[400px] mlg:max-w-lg ml-0 sm:ml-10'
				/>
			</div>
		</div>
	);
};

export default Home;

import React, { useEffect } from "react";

const QuestionResult = ({ title, options, active, isRadio, qIndex }) => {
  let votes = options.map((item, index) => item.votes);
  let totalVotes = votes.reduce((prev, current) => {
    return prev + current;
  }, 0);

  useEffect(() => {}, [title, options, isRadio, active]);

  return (
    <div className="flex flex-col items-center justify-center uppercase font-bold mt-6">
      <h1 className="text-lg md:text-xl md:ml-0 mb-2">{`${
        qIndex + 1
      }. ${title}`}</h1>
      <div className="flex flex-row">
        <h2 className="m-2 ml-0 text-sm">
          Status: {active ? "Active" : "Not active"}
        </h2>
        <h2 className="m-2 text-sm">
          Type: {isRadio ? "Single choice" : "Multiple choice"}
        </h2>
      </div>

      <div className="flex flex-col w-full">
        {options.map((item, index) => {
          let percentage = 0;
          if (totalVotes > 0) {
            percentage = (item.votes / totalVotes) * 100;
          }
          return (
            <div
              key={index}
              className="w-full bg-gray-200 rounded-lg dark:bg-gray-700 my-1 "
            >
              <div
                className="bg-main h-6 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-lg"
                style={{ width: `${percentage}%` }}
              >
                {" "}
                {percentage.toFixed(0)}%
              </div>
              {item.text} votes: {item.votes}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default QuestionResult;

import { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { useMoralis, useMoralisQuery } from "react-moralis";
import "animate.css";
import { WOW } from "wowjs";

import Navigation from "./components/Navigation";
import {
	Home,
	About,
	Checkout,
	Stakeholder,
	Media,
	Admin,
	ProtectedRoute,
	Footer,
} from "./components";

function App() {
	const [hasNFT, setHasNFT] = useState(false);
	const [ownedNFTs, setOwnedNFTs] = useState(null);
	const { user, isAuthenticated, Moralis, logout, isInitialized, isAuthenticating, isWeb3Enabled, enableWeb3 } =
		useMoralis();

	useEffect(() => {
		new WOW({ live: false }).init();
	}, []);

	const getNFTs = async (acc) => {
		if (acc) {
			const option = {
				chain: "polygon",
				address: acc,
				tokenAddresses: ["0xb2e762Ed5752435053D5E583290dA9AC29Fce31f"],
			};
			const NFTs = await Moralis.Web3API.account.getNFTsForContract(option);
			//const tokenIds = NFTs.result.map((item) => item.token_id);
			setOwnedNFTs(NFTs);
			NFTs.result.length > 0 ? setHasNFT(true) : setHasNFT(false);
		}
	};

	useEffect(async() => {
		const unsubscribeAccountChanged = Moralis.onAccountChanged((acc) => {
			logout();
		});

		const unsubscribeLogin = Moralis.onWeb3Enabled((result) => {
			if (!ownedNFTs) {
				getNFTs(result.account);
			}
		});

		enableWeb3();
	}, []);
	
	return (
		<div className='bg-background bg-top-4 bg-no-repeat bg-cover tracking-widest'>
			<div className='h-fit min-h-screen flex flex-col bg-gradient-to-t from-base via-base/0 to-base '>
				<Navigation hasNFT={hasNFT} />
				<div className='flex flex-col items-center h-full '>
					<Routes>
						<Route path='/' element={<Home />} />
						<Route path='/about' element={<About />} />
						<Route path='/checkout' element={<Checkout />} />
						<Route path='/media' element={<Media />} />
						<Route path='test' element={<Admin/>}/>
						<Route
							path='/stakeholder'
							element={
								<ProtectedRoute
									user={isAuthenticated}
									hasNFT={
										hasNFT ||
										user?.attributes?.role?.id === "atyZIyKqz6tGAo0cF4rGHLJ4"
									}>
									<Stakeholder />
								</ProtectedRoute>
							}
						/>
						<Route
							path='/admin'
							element={
								<ProtectedRoute
									user={
										user?.attributes?.role?.id === "atyZIyKqz6tGAo0cF4rGHLJ4"
									}
									hasNFT={
										user?.attributes?.role?.id === "atyZIyKqz6tGAo0cF4rGHLJ4"
									}>
									<Admin />
								</ProtectedRoute>
							}
						/>
					</Routes>
					<Footer />
				</div>
			</div>
		</div>
	);
}

export default App;

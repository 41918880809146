import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { Modal } from "../lib/Primitives";
import Card from "./Card";
import content from "../content";
import { images } from "../constants";
import { FaArrowAltCircleRight } from "react-icons/fa";

const Checkout = () => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <div className="flex flex-col h-fit items-center justify-center">
      <Helmet>
        <title>Purchase your NFT Now</title>
        <meta
          name="description"
          content="Purchase your InvestmentJoy NFT Now"
        />
        <meta
          name="keywords"
          content="nft, dao, investmentjoy, fractional nft, youtube nft, youtuber nft, popular nft, buy nft, real estate nft, business nft"
        />
      </Helmet>
      <h1 className="text-3xl font-bold mb-5 mt-20">Purchase your NFT Now</h1>
      <img
        src={images.ethCoin}
        alt="coin"
        className="max-w-[200px] mb-5 animate-myFlip"
      />
      <a
        href="https://opensea.io/collection/investment-joys-ccl-finance-token"
        target="_blank"
        rel="noreferrer"
        className="btn mb-10"
      >
        Buy NFT
      </a>
      <h1 className="text-3xl font-bold mb-5">Need help buying?</h1>
      <div className="flex flex-col md:flex-row mr-10 ml-10">
        {content.checkout.guide.map((item, index) => (
          <div
            key={index}
            className="wow fadeInLeft flex flex-1 mr-2 lg:mr-0 flex-row items-center mb-10 min-h-[350px]"
          >
            <Card
              img={item.img}
              title={item.title}
              text={item.text}
              btn={item.btn}
              link={item.link}
            />
            <FaArrowAltCircleRight
              className={`${
                index === content.checkout.guide.length - 1
                  ? "hidden"
                  : "hidden lg:block"
              } h-10 w-10 m-2`}
            />
          </div>
        ))}
      </div>
      <h1 className="text-3xl font-bold mb-5">Stay Protected</h1>
      <ul className="p-4 break-words text-sm md:text-[16px] leading-8">
        <li>• Never share your wallet password with anyone.</li>
        <li>• Never click on links unknown people send you.</li>
        <li>
          • Ensure any sites you navigate to have exactly the correct address.
        </li>
      </ul>
      <p className="p-4 break-words text-sm md:text-[16px] mb-5">
        If you have any questions join our{" "}
        <a
          href="#"
          target="_blank"
          rel="noreferrer"
          className="underline text-blue-700"
        >
          Discord
        </a>{" "}
        and ask for help in the NFT-SUPPORT chat
      </p>
      <button
        className="btn mb-10"
        onClick={() => {
          setModalOpen(true);
        }}
      >
        Learn more?
      </button>
      {modalOpen && (
        <>
          <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/ScMzIvxBSi4"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
              className="rounded-lg"
            ></iframe>
          </Modal>
        </>
      )}
    </div>
  );
};

export default Checkout;

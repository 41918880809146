import { images } from "../constants";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  nav: {
    links: [
      { text: "Home", to: "/" },
      { text: "Perks", to: "/about" },
      { text: "Checkout", to: "/checkout" },
      { text: "Media", to: "/media" },
    ],
  },
  checkout: {
    guide: [
      {
        img: images.metamaskFox,
        title: "Download MetaMask",
        text: "The Metamask extension will allow you to make purchases with Ethereum. If you are on mobile, please use the Metamask app.",
        link: "https://metamask.io/download/",
        btn: "Get Metamask",
      },
      {
        img: images.ethereum,
        title: "Buy ETH",
        text: "You can get ETH using a centralized exchange like Binance, Kraken, etc. or you can swap a different token for ETH using a DEX like Uniswap. ",
        link: "https://ethereum.org/en/get-eth/",
        btn: "ETH Guide",
      },
      {
        img: images.opensea,
        title: "Go to OpenSea by clicking the link above",
        text: "If this is your first time buying NFTs on OpenSea, the button below will take you to a short guide.",
        link: "https://support.opensea.io/hc/en-us/sections/1500000462261-Buying",
        btn: "OpenSea Guide",
      },
    ],
  },
  media: [
    { link: "", icon: images.discord, alt: "discord" },
    {
      link: "https://twitter.com/investmentjoy",
      icon: images.twitter,
      alt: "twitter",
    },
    {
      link: "https://www.instagram.com/investmentjoy/",
      icon: images.instagram,
      alt: "instagram",
    },
    {
      link: "https://www.youtube.com/c/investmentjoy",
      icon: images.youtube,
      alt: "youtube",
    },
    { link: "https://investmentjoy.com/", icon: images.web, alt: "website" },
  ],
};

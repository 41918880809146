import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useMoralisQuery, useMoralis } from "react-moralis";
import { images } from "../constants";
import Question from "./Question";

const Stakeholder = () => {
  const {
    fetch,
    data: questionsData,
    error: questionsError,
    isLoading: questionsLoading,
  } = useMoralisQuery("Question", (query) => query.equalTo("active", true));
  const { account } = useMoralis();
  return (
    <div className="flex flex-col-reverse mt-32 sm:flex-row items-start justify-center w-full mx-auto">
      <Helmet>
        <title>Make Your Voice Heard</title>
        <meta
          name="description"
          content="Make Your Voice Heard. Each NFT holder can vote on decisions regarding the InvestmentJoy NFTs."
        />
        <meta
          name="keywords"
          content="nft, dao, investmentjoy, fractional nft, youtube nft, youtuber nft, popular nft, real estate nft, business nft, buy nft"
        />
      </Helmet>
      <div className="flex flex-col items-start mr-5 lg:mr-20 ml-10 mb-10">
        <h1 className="uppercase mt-10 text-5xl font-bold">Current votes</h1>
        {!questionsLoading && questionsData[0]?.get("active") !== undefined
          ? questionsData.map((item, index) => {
            

              return (
                <Question
                  key={index}
                  title={item.get("title")}
                  options={item.get("options")}
                  isRadio={item.get("isRadio")}
                  objectId={item.id}
                  userVoted={item
                    .get("votedUsers")
                    .map(obj => obj.user)
                    .includes(account ? account : "")
                    }
                />
              );
            })
          : ""}
      </div>
      <div className="mx-auto sm:mx-0">
        <img
          src={images.card}
          alt="card"
          className=":max-w-[350px] md:max-w-[400px] mlg:max-w-lg ml-0 sm:ml-10"
        />
      </div>
    </div>
  );
};

export default Stakeholder;

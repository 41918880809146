import card from "../assets/card.png";
import card2 from "../assets/card.jxr";
import background from "../assets/background.png";
import menu from "../assets/menu.png";
import profile from "../assets/profile.png";
import metamask from "../assets/MetaMask.svg";
import metamaskFox from "../assets/MetaMask_Fox.png";
import walletconnect from "../assets/WalletConnect.svg";
import arrow from "../assets/right-arrow.png";
import ethereum from "../assets/ethereum-1.svg";
import opensea from "../assets/opensea.svg";
import ethCoin from "../assets/ethereum-eth.svg";
import twitter from "../assets/twitter.svg";
import discord from "../assets/discord.svg";
import instagram from "../assets/instagram.svg";
import youtube from "../assets/youtube.svg";
import web from "../assets/web.svg";

export default {
  card,
  card2,
  background,
  menu,
  profile,
  metamask,
  walletconnect,
  arrow,
  metamaskFox,
  ethereum,
  opensea,
  ethCoin,
  twitter,
  discord,
  instagram,
  youtube,
  web,
};

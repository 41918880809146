import React from "react";

const Card = ({ img, title, text, btn, link }) => {
  return (
    <div className="flex flex-col items-center lg:w-full h-full lg:max-w-xs bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
      <a href={link} target="_blank" rel="noreferrer">
        <img className="rounded-t-lg h-20 w-20 mt-5" src={img} alt="" />
      </a>
      <div className="flex flex-col items-center text-center p-5">
        <a href={link} target="_blank" rel="noreferrer">
          <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
            {title}
          </h5>
        </a>
        <p className="mb-3 font-normal text-sm lg:text-md text-gray-700 dark:text-gray-400">
          {text}
        </p>
        <a
          href={link}
          target="_blank"
          rel="noreferrer"
          className="btn text-sm lg:text-md mt-2 justify-self-end"
        >
          {btn}
        </a>
      </div>
    </div>
  );
};

export default Card;

import { cloneElement, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import cn from "classnames";

export function Card({ children, className, ...props }) {
  return (
    <div
      className={cn(
        "bg-base/80 rounded-3xl shadow-lg px-4 py-2 border border-white/20",
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
}

export function Portal({ children, ...props }) {
  const [portal, setPortal] = useState(null);

  const onClose = () => setPortal(null);

  useEffect(() => {
    const portal = document.createElement("div");
    document.body.appendChild(portal);
    setPortal(portal);
    return () => document.body.removeChild(portal);
  }, []);

  return portal && createPortal(cloneElement(children, { onClose }), portal);
}

export function Modal({ children, onClose, className, ...props }) {
  const [open, setOpen] = useState(true);

  const handleClick = (event) => {
    if (event.target == event.currentTarget) setOpen(false);
  };

  useEffect(() => {
    document.documentElement.style.overflow = "hidden";
    return () => {
      document.documentElement.style.overflow = "overlay";
    };
  }, [open]);

  return (
    <Portal>
      <div
        className={cn(
          "flex justify-center items-center fixed left-0 w-screen h-screen top-0 z-20 overflow-hidden",
          "bg-gray-800/40 backdrop-blur-sm blur-xxl",
          "transition-colors duration-500 animate__animated",
          open ? "animate__fadeIn" : "animate__fadeOut",
          className
        )}
        onClick={handleClick}
        onAnimationEnd={() => {
          if (!open) onClose();
        }}
        {...props}
      >
        <div className="fixed max-w-[800px] flex items-center overflow-auto">
          {children}
        </div>
      </div>
    </Portal>
  );
}

export function Skeleton(props) {
  return <div className="w-8 h-4 bg-gray-800 rounded animate-pulse"></div>;
}

import React from "react";
import { Helmet } from "react-helmet-async";
import content from "../content";

const Media = () => {
  return (
    <div className="flex flex-col h-[80vh] text-center items-center justify-center">
      <Helmet>
        <title>Learn All About Us</title>
        <meta
          name="description"
          content="Learn All About Us. Follow us on Instagram, Twitter, Youtube, or join our Discord."
        />
        <meta
          name="keywords"
          content="nft, dao, investmentjoy, fractional nft, youtube nft, youtuber nft, popular nft, real estate nft, business nft"
        />
      </Helmet>
      <h1 className="text-3xl font-bold mb-5 mt-20">
        Follow the Team Across Other Projects
      </h1>
      <div className="flex flex-row items-center justify-center">
        {content.media.map((item, index) => (
          <a
            key={index}
            href={item.link}
            target="_blank"
            rel="noreferrer"
            className="m-2"
          >
            <img
              src={item.icon}
              alt={item.alt}
              className="h-10 w-10 hover:ring-1 p-2 rounded-lg ring-white"
            />
          </a>
        ))}
      </div>
      <p>
        For any questions about the project join our Discord.
        <br />
        For any professional inquiries contact investmentjoyyt@gmail.com
      </p>
    </div>
  );
};

export default Media;

import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { useMoralis, useEnsAddress } from "react-moralis";
import { images } from "../constants";
import content from "../content";
import { getEllipsisTxt } from "../helpers/formatters";

const Navigation = ({ hasNFT }) => {
  const [active, setActive] = useState(0);
  const [navbarOpen, setNavbarOpen] = useState(false);
  const [signinOpen, setSigninOpen] = useState(false);
  const [heightMobile, setHeightMobile] = useState("0px");
  const [height, setHeight] = useState("0px");
  const { authenticate, enableWeb3, user, isAuthenticated, account, Moralis } =
    useMoralis();
  const { name, isLoading, error } = useEnsAddress(account);
  const [authError, setAuthError] = useState(null);
  const [isAuthenticating, setIsAuthenticating] = useState(false);

  const contentSpace = useRef(null);
  const signinSpace = useRef(null);

  const toggleMenu = () => {
    setNavbarOpen((prevState) => !prevState);
    setHeightMobile(
      navbarOpen ? "0px" : `${contentSpace.current.scrollHeight}px`
    );
  };

  const toggleSignIn = () => {
    setSigninOpen((prevState) => !prevState);
    setHeight(signinOpen ? "0px" : `${signinSpace.current.scrollHeight}px`);
  };
  
  useEffect(() => {
    const tab = window.location.href.split("/");
    switch (tab[3]) {
      case "":
        setActive(0);
        break;
      case "about":
        setActive(1);
        break;
      case "checkout":
        setActive(2);
        break;
      case "media":
        setActive(3);
        break;
      case "stakeholder":
        setActive(4);
        break;
      default:
        setActive(0);
    }
  }, []);

  const handleAuth = async (provider) => {
    try {
      if (isAuthenticating == false) {
        setAuthError(null);
        setIsAuthenticating(true);

        // Enable web3 to get user address and chain
        await enableWeb3({ throwOnError: true, provider });
        const { account, chainId } = Moralis;

        if (!account) {
          throw new Error('Connecting to chain failed, as no connected account was found');
        }
        if (!chainId) {
          throw new Error('Connecting to chain failed, as no connected chain was found');
        }

        // Get message to sign from the auth api
        const { message } = await Moralis.Cloud.run('requestMessage', {
          address: account,
          chain: parseInt(chainId, 16),
          network: 'evm',
        });

        // Authenticate and login via parse
        await authenticate({
          signingMessage: message,
          throwOnError: true,
        });
      }
    } catch (error) {
      setAuthError(error);
    } finally {
      setIsAuthenticating(false);
    }
  }

  return (
    <div className=" top-0 bg-transparent">
      <div className="flex flex-row-reverse lg:flex-row items-center justify-between w-11/12 pt-5 mx-auto py-3">
        <div className="hidden lg:block  lg:ml-20">
          {content.nav.links.map((item, index) => {
            return (
              <Link
                key={index}
                to={item.to}
                onClick={() => {
                  setActive(index);
                }}
                className={`px-6 py-3 mx-2 lg:mx-4 uppercase font-semibold text-sm lg:text-md transition ease-in-out delay-75 rounded-b-lg bg-gradient-to-b from-base/0 to-base/75 ${
                  active === index
                    ? "shadow-navactive drop-shadow-navactive"
                    : ""
                }`}
              >
                {item.text}
              </Link>
            );
          })}
          {((isAuthenticated && hasNFT) ||
            user?.attributes?.role?.id === "atyZIyKqz6tGAo0cF4rGHLJ4") && (
            <Link
              to={"/stakeholder"}
              onClick={() => {
                setActive(content.nav.links.length);
              }}
              className={`px-6 py-3 mx-2 lg:mx-4 uppercase font-semibold text-sm lg:text-md transition ease-in-out delay-75 rounded-b-lg bg-gradient-to-b from-base/0 to-base/75 ${
                active === content.nav.links.length
                  ? "shadow-navactive drop-shadow-navactive"
                  : ""
              }`}
            >
              {"Stakeholder"}
            </Link>
          )}
          {/* {isAuthenticated && (
            <Link
              to={"/stakeholder"}
              onClick={() => {
                setActive(content.nav.links.length);
              }}
              className={`px-6 py-3 mx-2 lg:mx-4 uppercase font-semibold text-sm lg:text-md transition ease-in-out delay-75 rounded-b-lg bg-gradient-to-b from-base/0 to-base/75 ${
                active === content.nav.links.length
                  ? "shadow-navactive drop-shadow-navactive"
                  : ""
              }`}
            >
              {"Stakeholder"}
            </Link>
          )} */}
          {account &&
            user?.attributes?.role?.id === "atyZIyKqz6tGAo0cF4rGHLJ4" && (
              <Link
                to={"/admin"}
                onClick={() => {
                  setActive(content.nav.links.length + 1);
                }}
                className={`px-6 py-3 mx-2 lg:mx-4 uppercase font-semibold text-sm lg:text-md transition ease-in-out delay-75 rounded-b-lg bg-gradient-to-b from-base/0 to-base/75 ${
                  active === content.nav.links.length + 1
                    ? "shadow-navactive drop-shadow-navactive"
                    : ""
                }`}
              >
                {"Admin"}
              </Link>
            )}
        </div>
        <button
          className={`hidden lg:flex items-center px-6 py-3 lg:mr-10 uppercase font-semibold text-sm lg:text-md rounded-b-lg bg-gradient-to-t from-base/0 to-base/75 active:shadow-navactive active:drop-shadow-navactive ${
            signinOpen ? "drop-shadow-navactive shadow-navactive" : ""
          }`}
          type="button"
          onClick={toggleSignIn}
        >
          {name
            ? name
            : account && isAuthenticated
            ? getEllipsisTxt(account)
            : "Sign in"}
          {!account && (
            <img
              src={images.profile}
              alt="sign in"
              className="h-5 inline-block ml-2"
            />
          )}
        </button>
        <button
          className="text-white lg:hidden hover:shadow-bdefault cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block outline-none focus:outline-none"
          type="button"
          onClick={toggleMenu}
        >
          <img src={images.menu} alt="menu" className="h-8 w-8 filter invert" />
        </button>
      </div>
      <div
        ref={contentSpace}
        style={{ maxHeight: `${heightMobile}` }}
        className=" fixed right-8 bg-base rounded-b-lg shadow-lg text-main transition-max-height duration-700 ease-in-out overflow-hidden z-20"
      >
        <div className="flex flex-col justify-center items-center pb-2 pt-2 font-bold text-md sm:text-lg md:text-xl">
          <button
            className="flex items-center px-6 py-3 shadow-md lg:mr-10 uppercase font-semibold text-sm lg:text-md rounded-b-lg bg-gradient-to-t from-base/0 to-base/75 active:shadow-navactive active:drop-shadow-navactive"
            onClick={toggleSignIn}
          >
            {name
              ? name
              : user?.attributes.ethAddress && isAuthenticated
              ? getEllipsisTxt(user?.attributes.ethAddress)
              : "Sign in"}
            {!user?.attributes.ethAddress && (
              <img
                src={images.profile}
                alt="sign in"
                className="h-5 inline-block ml-2"
              />
            )}
          </button>
          {content.nav.links.map((item, index) => {
            return (
              <Link
                key={index}
                to={item.to}
                onClick={() => {
                  setActive(index);
                }}
                className={`px-6 py-3 mx-2 lg:mx-4 uppercase font-semibold text-sm lg:text-md transition ease-in-out delay-75 rounded-b-lg bg-gradient-to-b from-base/0 to-base/75 ${
                  active === index
                    ? "shadow-navactive drop-shadow-navactive"
                    : ""
                }`}
              >
                {item.text}
              </Link>
            );
          })}
          {((isAuthenticated && hasNFT) ||
            user?.attributes?.role?.id === "atyZIyKqz6tGAo0cF4rGHLJ4") && (
            <Link
              to={"/stakeholder"}
              onClick={() => {
                setActive(content.nav.links.length);
              }}
              className={`px-6 py-3 mx-2 lg:mx-4 uppercase font-semibold text-sm lg:text-md transition ease-in-out delay-75 rounded-b-lg bg-gradient-to-b from-base/0 to-base/75 ${
                active === content.nav.links.length
                  ? "shadow-navactive drop-shadow-navactive"
                  : ""
              }`}
            >
              {"Stakeholder"}
            </Link>
          )}
          {/* {isAuthenticated && (
            <Link
              to={"/stakeholder"}
              onClick={() => {
                setActive(content.nav.links.length);
              }}
              className={`px-6 py-3 mx-2 lg:mx-4 uppercase font-semibold text-sm lg:text-md transition ease-in-out delay-75 rounded-b-lg bg-gradient-to-b from-base/0 to-base/75 ${
                active === content.nav.links.length
                  ? "shadow-navactive drop-shadow-navactive"
                  : ""
              }`}
            >
              {"Stakeholder"}
            </Link>
          )} */}
          {account &&
            user?.attributes?.role?.id === "atyZIyKqz6tGAo0cF4rGHLJ4" && (
              <Link
                to={"/admin"}
                onClick={() => {
                  setActive(content.nav.links.length);
                }}
                className={`px-6 py-3 mx-2 lg:mx-4 uppercase font-semibold text-sm lg:text-md transition ease-in-out delay-75 rounded-b-lg bg-gradient-to-b from-base/0 to-base/75 ${
                  active === content.nav.links.length
                    ? "shadow-navactive drop-shadow-navactive"
                    : ""
                }`}
              >
                {"Admin"}
              </Link>
            )}
        </div>
      </div>
      <div
        ref={signinSpace}
        style={{ maxHeight: `${height}` }}
        className="block fixed right-14 w-5/12 md:w-3/12 xl:w-2/12  bg-base rounded-b-lg shadow-lg text-main transition-max-height duration-700 ease-in-out overflow-hidden z-30"
      >
        <div className="flex flex-col px-4  justify-center items-center pb-2 pt-2 font-bold text-md sm:text-lg md:text-xl">
          <button className="h-5 self-end" onClick={toggleSignIn}>
            <img
              src={images.arrow}
              alt="close menu"
              className="h-5 -rotate-90 mr-5"
            />
          </button>
          <button
            className="w-full py-4 flex items-center justify-center active:shadow-navactive active:drop-shadow-navactive rounded-lg"
            onClick={() => {
              handleAuth("metamask");
              //authenticate({ signingMessage: "Welcome to Investment Joy NFT" });
            }}
          >
            <img src={images.metamask} alt="metamask" className="w-9/12" />
          </button>
          <div className="h-[2px] w-full bg-gradient-to-r from-main/0 via-main to-main/0"></div>
          <button
            className="w-full py-4 flex items-center justify-center active:shadow-navactive active:drop-shadow-navactive rounded-lg"
            onClick={() => {
              handleAuth("walletConnect");
              /*Moralis.authenticate({
                provider: "walletConnect",
                chainId: 80001,
              });
              */
            }}
          >
            <img
              src={images.walletconnect}
              alt="walletconnect"
              className="w-9/12"
            />
          </button>
          <div className="h-[2px] w-full bg-gradient-to-r from-main/0 via-main to-main/0 mb-4"></div>
        </div>
      </div>
    </div>
  );
};

export default Navigation;

import React, { useEffect, useState } from "react";
import { useMoralis, useMoralisCloudFunction } from "react-moralis";

const ToggleInput = ({
  name,
  state,
  options,
  data,
  questionData,
  setQuestionData,
}) => {
  const [checked, setChecked] = useState(state);

  return (
    <label
      htmlFor={`toggle-${name}`}
      className="flex relative items-center mb-4 cursor-pointer"
    >
      <input
        type="checkbox"
        id={`toggle-${name}`}
        className="sr-only"
        checked={checked}
        onChange={(e) => {
          setChecked(e.target.checked);
          if (data === "active") {
            setQuestionData({ ...questionData, active: e.target.checked });
          } else {
            setQuestionData({ ...questionData, isRadio: e.target.checked });
          }
        }}
      />
      <div className="w-11 h-6 bg-gray-200 rounded-full border border-gray-200 toggle-bg dark:bg-gray-700 dark:border-gray-600"></div>
      <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
        {checked ? options[0] : options[1]}
      </span>
    </label>
  );
};

const Form = ({ currentId, setCurrentId, qData }) => {
  const [questionData, setQuestionData] = useState({
    title: "",
    options: [{}],
    active: false,
    isRadio: false,
    votedUsers: [],
    votedTokens: [],
  });
  const [optionData, setOptionData] = useState("");
  const { Moralis } = useMoralis();

  const handleSubmit = async () => {
    if (currentId) {
      await Moralis.Cloud.run("updateQuestion", {
        question: questionData,
        objectId: currentId,
      });
    } else {
      await Moralis.Cloud.run("addQuestion", {
        question: questionData,
      });
    }
  };

  const clear = () => {
    setQuestionData({
      title: "",
      options: [{}],
      active: false,
      isRadio: false,
      votedUsers: [],
      votedTokens: [],
    });
    setOptionData("");
    setCurrentId(null);
  };

  useEffect(() => {
    if (currentId) {
      const answers = qData.options.map((item) => item.text);
      setOptionData(answers.toString());
      setQuestionData(qData);
    }
  }, [currentId]);

  return (
    <div className="flex flex-col flex-wrap w-2/3 justify-center items-center rounded-lg bg-base border-solid border-white border-2 p-4">
      <form
        autoComplete="off"
        noValidate
        onSubmit={handleSubmit}
        className="flex flex-col flex-wrap justify-center"
      >
        <h2 className="text-xl mb-4">
          {currentId ? "Editing" : "Creating"} a question
        </h2>
        <div className="relative z-0 mb-6 w-full group">
          <input
            type="text"
            name="floating_title"
            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
            placeholder=" "
            value={questionData.title}
            onChange={(e) =>
              setQuestionData({ ...questionData, title: e.target.value })
            }
            required
          />
          <label
            htmlFor="floating_title"
            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >
            Title
          </label>
        </div>
        <div className="relative z-0 mb-6 w-full group">
          <input
            type="text"
            name="floating_options"
            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
            value={optionData}
            onChange={(e) => {
              setOptionData(e.target.value);
              const arr = e.target.value.split(",");
              const objArr = arr.map((item) => {
                return { text: item, votes: 0 };
              });

              setQuestionData({ ...questionData, options: objArr });
            }}
            required
          />
          <label
            htmlFor="floating_options"
            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >
            Options
          </label>
        </div>
        <ToggleInput
          name="Multiple Choice"
          state={questionData.active}
          options={["Single Choice", "Multiple Choice"]}
          data="isRadio"
          questionData={questionData}
          setQuestionData={setQuestionData}
        />
        <ToggleInput
          name="Active"
          state={questionData.isRadio}
          options={["Active", "Inactive"]}
          data="active"
          questionData={questionData}
          setQuestionData={setQuestionData}
        />
      </form>
      <div>
        <button className="btn" onClick={handleSubmit}>
          Submit
        </button>
        <button className="btn" onClick={clear}>
          Clear
        </button>
      </div>
    </div>
  );
};

export default Form;

import React from "react";

const Footer = () => {
  return (
    <div className="p-2 text-[10px] text-center ">
      <p className="align-middle">
        All money used to purchase fractionalized tokens of the “COINCOKEMAN”
        NFT could be lost. Brandon Schlichter as the Manager of the
        “COINCOKEMAN” NFT project, Blue Sky Synergy Media Corp. and Investment
        Joy are not providing financial advice through the “COINCOKEMAN” NFT
        project or Website. None of the above-listed persons and entities claims
        to be or is acting in the best interest of the Stakeholders through the
        “COINCOKEMAN” NFT project. The “COINCOKEMAN” NFT project is not an
        investment.
        <br />
        <br /> Copyright 2022, all rights reserved.
      </p>
    </div>
  );
};

export default Footer;

import React, { useState } from "react";
import { useMoralis } from "react-moralis";

const Question = ({ title, options, isRadio, userVoted, objectId }) => {
  const [checkedState, setCheckedState] = useState(
    new Array(options.length).fill(false)
  );
  const [voted, setVoted] = useState(false);
  const [answers, setAnswers] = useState([]);
  const { Moralis } = useMoralis();

  const handleOnChange = (position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );
    const filtered = options.filter(
      (item, index) => updatedCheckedState[index] === true
    );
    const result = filtered.map((item) => item.text);
    setAnswers(result);

    setCheckedState(updatedCheckedState);
  };

  const handleOnChangeRadio = (position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? true : false
    );
    const filtered = options.filter((item, index) => index === position);
    const result = filtered.map((item) => item.text);

    setCheckedState(updatedCheckedState);
    setAnswers(result);
  };

  const handleClick = async () => {
    const result = await Moralis.Cloud.run("vote", {
      objectId: objectId.toString(),
      answers: answers,
    });
    setVoted(true);
  };

  return (
    <div className="flex flex-col items-start justify-start uppercase font-bold mt-6">
      <h1 className="text-xl mb-6">{title}</h1>
      <div>
        {options.map((item, index) => {
          return (
            <div
              key={index}
              className="flex flex-row items-center text-[15px] mb-6"
            >
              {!isRadio ? (
                <input
                  type="checkbox"
                  value={item.text}
                  className="form-checkbox text-main rounded-md h-5 w-5"
                  checked={checkedState[index]}
                  onChange={() => handleOnChange(index)}
                />
              ) : (
                <input
                  type="checkbox"
                  name="flexRadio"
                  className=" h-5 w-5 text-main"
                  checked={checkedState[index]}
                  onChange={() => handleOnChangeRadio(index)}
                />
              )}
              <span className="ml-6 ">{item.text}</span>
            </div>
          );
        })}
      </div>
      {!voted ? (
        <button className="btn ml-0" onClick={handleClick}>
          Submit Vote
        </button>
      ) : (
        <span className="">Your vote has been submitted!</span>
      )}
      <br/>
      {!userVoted && !voted ? (
        <p></p>
      ): (<p>You have voted on this question. Click Submit Vote to change your response</p>)}
    </div>
  );
};

export default Question;

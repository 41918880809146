import React, { useState } from "react";
import { useMoralisQuery, useMoralis } from "react-moralis";
import QuestionResult from "./QuestionResult";
import Form from "./Form";

const Admin = () => {
  const {
    data: questionsData,
    error: questionsError,
    isLoading: questionsLoading,
  } = useMoralisQuery("Question", (query) => query, [], { live: true });
  const [currentId, setCurrentId] = useState(null);
  const [qData, setQData] = useState({});
  const { Moralis } = useMoralis();

  return (
    <div className="flex flex-col-reverse md:flex-row justify-center items-center md:justify-between md:items-start w-full mx-auto mt-10">
      <div className="flex flex-1 flex-col items-center justify-center md:items-start md:mr-5  mb-10 ml-5">
        <h1 className="uppercase self-center text-4xl md:text-5xl font-bold">
          Questions
        </h1>
        {questionsData?.map((item, index) => (
          <div
            key={index}
            className="flex flex-col self-center items-start justify-start uppercase font-bold mt-6"
          >
            <QuestionResult
              title={item.attributes.title}
              options={item.attributes.options}
              active={item.attributes.active}
              isRadio={item.attributes.isRadio}
              qIndex={index}
            />
            <div className="flex flex-row items-center justify-center w-full mt-2">
              <button
                className="btn"
                onClick={() => {
                  setCurrentId(item.id);
                  setQData({
                    title: item.attributes.title,
                    options: item.attributes.options,
                    active: item.attributes.active,
                    isRadio: item.attributes.isRadio,
                  });
                }}
              >
                Edit
              </button>
              <button
                className="btn"
                onClick={async () => {
                  await Moralis.Cloud.run("deleteQuestion", {
                    objectId: item.id,
                  });
                }}
              >
                Delete
              </button>
            </div>
          </div>
        ))}
      </div>
      <div className="flex flex-1 flex-col h-full items-center mb-10 md:mb-0 w-full ">
        <Form currentId={currentId} setCurrentId={setCurrentId} qData={qData} />
      </div>
    </div>
  );
};

export default Admin;
